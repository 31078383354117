<template>
  <div>
    <div class="header">
      <div>Вы уверены что хотите {{ data.status === 'approval' ? 'подтвердить' : 'оформить' }} заявку?</div>
    </div>
    <div class="confirm">
      <div class="item">
        <div class="title">Направление:</div>
        <span>{{ data.action === 'buy' ? 'Покупка' : 'Продажа' }}</span>
      </div>
      <div class="item">
        <div class="title">Тип ордера:</div>
        <span style="text-transform: uppercase">{{ data.type }}</span>
      </div>

      <div class="item">
        <div class="title">Инструмент:</div>
        <span>{{ data.instrument.symbol }}</span>
      </div>
      <div class="item">
        <div class="title">Количество:</div>
        <span>{{ data.quantity }}</span>
      </div>
      <div v-if="data.price > 0" class="item">
        <div class="title">Цена:</div>
        <span>{{ utils.getLocateCurrency(data.price) }}</span>
      </div>
      <div v-if="data.limitPrice > 0" class="item">
        <div class="title">Цена:</div>
        <span>{{ utils.getLocateCurrency(data.limitPrice) }}</span>
      </div>
      <div v-if="data.triggerPrice > 0" class="item">
        <div class="title">Stop цена:</div>
        <span>{{ utils.getLocateCurrency(data.triggerPrice) }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import utils from '@/utils';

export default {
  name: 'confirmation-order',
  emits: '',
  components: {},
  data() {
    return {};
  },
  computed: {
    utils() {
      return utils;
    },
  },
  props: {
    data: { type: Object },
  },
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.header {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
}

.confirm {
  width: 70%;
  margin: 0 auto;
  @include breakpoint-down(md) {
    width: 90%;
    margin: 0 auto;
  }

  .item {
    border-bottom: 1px solid var(--input-alt-default-border-outside);
    margin-bottom: 8px;
    padding-bottom: 2px;
    display: flex;
    justify-content: space-between;

    span {
      font-weight: 600;
    }
  }
}
</style>
