<template>
  <div class="main-page">
    <div class="container">
      <div class="new-wrap">
        <div class="main-left">
          <div class="chart-wrap">
            <div class="widget-bg">
              <chart-headlinne />
              <chart-component />
            </div>
          </div>

          <div :style="{ transition: 'height ' + delay + 'ms' }" class="portfolio-wrap">
            <div class="widget-bg">
              <div class="tabs-header-line">
                <div class="table-setting">
                  <div v-if="selectedTab === 'Portfolio'">
                    <!-- <input v-model="selectedDate" type="date" @change="getTimestamp" /> -->
                  </div>
                  <div v-if="selectedTab === 'Orders'" style="display: flex">
                    <div class="datapicker-wrap">
                      <!-- <input v-model="selectedDate" type="date" @change="getTimestamp" /> -->
                      <!--                      <VueDatePicker-->
                      <!--                        :dark="true"-->
                      <!--                        :enable-time-picker="false"-->
                      <!--                        :hide-navigation="['time']"-->
                      <!--                        :model-value="date"-->
                      <!--                        auto-apply-->
                      <!--                        locale="ru"-->
                      <!--                        model-type="timestamp"-->
                      <!--                        @update:model-value="handleDate"-->
                      <!--                      />-->
                    </div>
                  </div>
                  <div v-if="selectedTab === 'Money'">
                    <!--                    money setting-->
                  </div>

                  <div
                    :title="tableHeight ? 'свернуть панель' : 'развенруть панель'"
                    class="change-height-icon"
                    @click="collapse('.main-left')"
                  >
                    <img :src="tableHeight ? icons.collapse : icons.height" alt="" />
                  </div>

                  <div
                    :title="fullScreenTable ? 'стандартный размер' : 'на весь экран'"
                    class="change-height-icon"
                    @click="fullHeight('.main-left')"
                  >
                    <img :src="fullScreenTable ? icons.defaultHeight : icons.fullHeight" alt="" />
                  </div>
                </div>
              </div>
              <div class="index-tabs">
                <tabs :options="{ useUrlFragment: false }" @changed="tabChanged">
                  <tab name="Portfolio">
                    <portfolio-table-component />
                  </tab>
                  <tab name="Orders">
                    <order-table-component />
                  </tab>
                </tabs>
              </div>
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="watch-list-wrap">
            <div class="widget-bg">
              <watchlist-component class="watchlist" />
            </div>
          </div>
          <div v-if="!modals.orderForm.visible" class="widget-bg">
            <order-form-component />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import WatchlistComponent from '@/components/WatchlistComponent';
import OrderFormComponent from '@/components/OrderFormComponent';
import ChartHeadlinne from '@/components/chart/ChartHeadline.vue';
import PortfolioTableComponent from '@/components/PortfolioTableComponent';
import ChartComponent from '@/components/ChartComponent';
import { Tabs, Tab } from '../../node_modules/vue3-tabs-component';
import OrderTableComponent from '@/components/OrderTableComponent';
// import VueDatePicker from '@vuepic/vue-datepicker';

export default {
  name: 'IndexView',
  components: {
    ChartHeadlinne,
    ChartComponent,
    WatchlistComponent,
    OrderFormComponent,
    // VueDatePicker,
    PortfolioTableComponent,
    Tabs,
    Tab,
    OrderTableComponent,
  },
  data() {
    return {
      icons,
      fullScreenTable: false,
      tableHeight: true,
      days: [
        { value: '1', text: 'за 1 день' },
        { value: '3', text: 'за 3 дня' },
        { value: '7', text: 'за 7 дней' },
        { value: '15', text: 'за 15 дней' },
        { value: '30', text: 'за 30 дей' },
        { value: 'all', text: 'all' },
      ],
      selectedTab: '',
      orderFormKey: new Date().getTime(),
      windowWidth: window.innerWidth,
      delay: 200,
      selectedDate: '',
      timestamp: null,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
      modals: 'getModals',
    }),
  },
  methods: {
    ...mapMutations({
      toggleChartHeight: 'toggleChartHeight',
      changeSymbols: 'changeSymbols',
    }),
    ...mapActions({
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    handleDate(modelData) {
      this.date = modelData;
      console.log('Selected date:', modelData);
    },
    getTimestamp() {
      this.selectedDate ? (this.timestamp = new Date(this.selectedDate).getTime()) : (this.timestamp = null);
      console.log(this.timestamp);
    },
    getEeternalParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const symbol = urlParams.get('symbol');
      const source = urlParams.get('source');
      const asset_category = urlParams.get('type');

      if (symbol && source && asset_category) {
        this.changeSymbols({
          [symbol]: { symbol, source, asset_category },
        });
        this.updateSelectedSymbol(symbol);
        this.clearURL();
      }
    },
    clearURL() {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split('?')[0];
      window.history.replaceState(null, '', newUrl);
    },
    tabChanged(e) {
      this.selectedTab = e.tab.name;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    confirmReload(event) {
      const confirmationMessage = undefined;
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    resizeHeightChart() {
      setTimeout(() => this.toggleChartHeight(), this.delay);
    },
    fullHeight(item) {
      let block = document.querySelector(item);
      block.classList.toggle('full-height');
      this.fullScreenTable = !this.fullScreenTable;
      this.tableHeight = true;
      this.resizeHeightChart();
    },
    async collapse(item) {
      let block = document.querySelector(item);
      block.classList.toggle('collapse');
      block.classList.remove('full-height');
      this.fullScreenTable = false;
      (this.tableHeight = !this.tableHeight), this.resizeHeightChart();
    },
  },
  watch: {
    windowWidth(newWidth) {
      if (newWidth <= 800) this.$router.push({ name: 'portfolio' });
    },
  },
  created() {
    if (this.isMobile) this.$router.push({ name: 'portfolio' });
  },
  mounted() {
    this.getEeternalParams();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeunload', this.confirmReload);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('beforeunload', this.confirmReload);
  },
};
</script>

<style lang="scss" scoped>
// style for datapicker
.datapicker-wrap {
  width: 140px;

  ::v-deep(.dp__input) {
    height: 100% !important;
  }
}

.dp__theme_dark,
::v-deep(.dp__theme_dark) {
  --dp-background-color: rgba(36, 38, 55, 1);
  --dp-border-color: rgba(66, 69, 108, 1);
  --dp-hover-color: rgba(54, 56, 84, 1);
  --dp-menu-border-color: rgba(66, 69, 108, 1);
  --dp-input-padding: 2px 30px 2px 12px;
  --dp-cell-padding: 2px;
  --dp-cell-size: 25px;
}

.main-page {
  .change-height-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .new-wrap {
    display: flex;
  }

  .main-right {
    flex: 1;
    flex-shrink: 0;
    max-width: 360px;
    width: 100%;
    margin-left: 1px;
    overflow: hidden;
    padding: 2px;
    height: calc(100vh - var(--setting-line-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-left {
    width: calc(100% - 360px);
    flex: 1;
    height: calc(100vh - var(--setting-line-height));

    &.collapse {
      .portfolio-wrap {
        height: 30px;
      }

      .chart-wrap {
        height: calc(100% - 30px);
      }

      .arrow-collapse {
        transform: rotateX(0);
      }
    }

    &.full-height {
      .portfolio-wrap {
        height: 100%;
      }

      .chart-wrap {
        height: 2px;
      }
    }
  }

  .portfolio-wrap {
    height: 50%;
    position: relative;
    z-index: 9999;

    .widget-bg {
      height: 100%;
    }
  }

  .chart-wrap {
    height: 50%;
    transition: height 0.2s ease-in-out;

    .widget-bg {
      height: 100%;
      position: relative;
    }
  }

  .watch-list-wrap {
    height: 100%;
    overflow: hidden;

    .widget-bg {
      height: 100%;
    }

    .watchlist {
      height: calc(100% - 48px);
    }
  }
}

.table-setting {
  display: flex;
}

.tabs-header-line {
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
