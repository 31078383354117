<template>
  <div class="info">
    Количество бумаг, выставленных в приказе, превышает количество бумаг в вашем портфеле, это приведёт к открытию позиции SHORT.
  </div>
</template>
<script>
export default {
  name: 'confirmation-short',
  emits: '',
  components: {},
  data() {
    return {};
  },
  computed: {},
  props: {},
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.info {
  max-width: 400px;
  margin: 0 auto 160px;
  text-align: center;
}
</style>
