/* eslint-disable no-unused-vars */
import utils from '@/utils';
import calc from '@/store/calc.js';
import storeDefault from '@/store/default.js';
import boarding from '@/store/boarding.js';
import { createStore } from 'vuex';

export default createStore({
  state: () => storeDefault.initialState(),
  getters: {
    isLoggedIn: (state) => !!state.token,
    getTOTP: (state) => state.totp,
    // authStatus: (state) => state.status,
    getNotifications: (state) => state.notifications,
    getUser: (state) => state.user,
    getPersonalData: (state) => state.personalData,
    getProfile: (state) => state.profile,
    getAutoApproval: (state) => state.autoApproval,
    getDocuments: (state) => state.documents,
    getModals: (state) => state.modals,
    getConfirmation: (state) => state.confirmation,
    getAccountSelected: (state) => state.accountSelected,
    getAccounts: (state) => state.accounts,
    getSymbols: (state) => state.symbols,
    getSelectedSymbol: (state) => state.selectedSymbol,
    getLevelI: (state) => state.levelI,
    getPortfolio: (state) => state.portfolio,
    getOrderSetting: (state) => state.orderSetting,
    getOptions: (state) => state.options,
    getExperations: (state) => state.experations,
    getClientsListNames: (state) => state.clientsListNames,
    getOrders: (state) => state.orders,
    getOrderStatus: (state) => state.orderStatus,
    getOrderType: (state) => state.orderType,
    getOrder: (state) => state.order,
    getMassInstrumentsOrder: (state) => state.massInstrumentsOrder,
    getSelectedWl: (state) => state.selectedWl,
    getWatchList: (state) => state.watchList,
    getSearchList: (state) => state.searchList,
    getChartType: (state) => state.chartType,
    getChartMainIndicators: (state) => state.chartMainIndicators,
    getChartSubIndicator: (state) => state.chartSubIndicator,
    getIndicatorSettings: (state) => state.indicatorSettings,
    getTimeFrameSelected: (state) => state.timeFrameSelected,
    getTimeUpdateTerminal: (state) => state.timeUpdateTerminal,
    getIntervalNumber: (state) => state.intervalNumber,
    getUpdateWatchList: (state) => state.updateWatchList,
    isMobile: (state) => window.innerWidth <= state.widthDisplay,
    getChartHeight: (state) => state.chartHeight,
    // removedIndicator: (state) => state.removeIndicator,
  },
  mutations: {
    toggleChartHeight(state) {
      state.chartHeight = !state.chartHeight; // меняем значение переменной на противоположное
    },
    changeUser(state, data) {
      for (const key in data) state.user[key] = data[key];
      // if (state.user.wl.length === 1 && state.user.wl[0] === 'main') {
      // if (state.user.type === 'manager' && !state.isMobile) state.user.wls['hot'] = 'hot';
      // }
    },
    changePersonalData(state, data) {
      for (const key in data) state.personalData[key] = data[key];
    },
    changeProfile(state, data) {
      for (const key in data) state.profile[key] = data[key];
    },
    changeAutoApproval(state, data) {
      state.autoApproval = data;
    },
    changeWithdrawal(state, data) {
      state.autoApproval = data;
    },
    changeDocuments(state, data) {
      state.documents = [];
      for (const each of data) state.documents.push(each);
    },
    changeModals(state, data) {
      for (const key in data) state.modals[key] = data[key];
    },
    changeOptions(state, data) {
      state.options = data;
    },
    changeExperations(state, data) {
      state.experations = data;
    },
    changeConfirmation(state, data) {
      state.confirmation = data;
    },
    changeToken(state, token) {
      token ? localStorage.setItem('session.token', token) : localStorage.removeItem('session.token');
      state.token = token;
    },
    changeTOTP(state, cognito) {
      state.totp = cognito;
    },
    changeSymbols(state, data) {
      state.symbols = data;
    },
    deleteSymbol(state, symbol) {
      delete state.symbols[symbol];
    },
    changeNotifications(state, data) {
      state.notifications.push(storeDefault.initialNotification(data));
    },
    cleanNotification(state) {
      state.notifications = [];
    },
    changeSelectedSymbol(state, symbol) {
      state.selectedSymbol = symbol;
    },
    changeLevelI(state, data) {
      state.levelI[data.symbol] = { bid: data.bid, bidSize: data.bid_size, ask: data.ask, askSize: data.ask_size };
    },
    changePortfolio(state, data) {
      state.portfolio = data;
    },
    updatePortfolio(state) {
      const { symbols, portfolio } = state;
      if (portfolio.items.length === 0) return;
      calc.updatePortfolioPrice({ portfolio, symbols });
      // console.log('end: ', JSON.stringify(state.portfolio));
    },
    changeSelectedWl(state, data) {
      state.selectedWl = data;
    },
    changeWatchList(state, data) {
      state.watchList = data;
    },
    changeWlName(state, { key, value }) {
      state.user.wls[key] = value;
    },
    changeSearchList(state, data) {
      state.searchList = data;
    },
    changeAccounts(state, accounts) {
      state.accounts = accounts;
    },
    changeClientsListNames(state, lists) {
      state.clientsListNames = [];
      for (const list of lists) state.clientsListNames.push(list);
    },
    changeListOrders(state, data) {
      state.orders = data;
    },
    changeOrderSetting(state, data) {
      state.orderSetting = data;
    },
    setDefaultOrder(state, { type = 'limit', tif = 'gtc' }) {
      state.order = storeDefault.initialOrder({
        symbol: state.selectedSymbol,
        type,
        tif,
        list: [storeDefault.initialAccount(state.accounts[state.accountSelected])],
      });
      // state.order.deal.general.symbol = state.selectedSymbol;
      // state.order.list = [storeDefault.initialAccount(state.accounts[state.accountSelected])];
    },
    changeOrderList(state, { account, add = false, quantity = null }) {
      let indexOrderAccount = state.order.list.findIndex((each) => each.account === account);
      if (add) {
        if (indexOrderAccount === -1) {
          state.order.list.push(storeDefault.initialAccount(state.accounts[account]));
          indexOrderAccount = state.order.list.findIndex((each) => each.account === account);
        }
        state.order.list[indexOrderAccount].quantity = quantity || state.order.deal.general.quantity;
      } else {
        if (indexOrderAccount !== -1) state.order.list.splice(indexOrderAccount, 1);
      }
    },
    changeOrder(state, data) {
      if ('symbol' in data) state.order.deal.general.symbol = data.symbol;
      if ('quantity' in data) state.order.deal.general.quantity = Math.abs(data.quantity);
      if ('action' in data) state.order.deal.orders[1].action = data.action;
      if ('tif' in data) state.order.deal.orders[1].tif = data.tif;
      if ('accounts' in data) state.order.list = data.accounts;
      if ('type' in data) state.order.deal.orders[1].type = data.type;
      if ('limitPrice' in data) state.order.deal.orders[1].limitPrice = data.limitPrice;
      if ('triggerPrice' in data) state.order.deal.orders[1].triggerPrice = data.triggerPrice;
    },
    changeMassInstrumentsOrder(state, data) {
      state.massInstrumentsOrder = data;
    },
    changeChartType(state, type) {
      state.chartType = type;
    },
    changeTimeFrameSelected(state, time) {
      if (state.timeFrameSelected !== time) state.timeFrameSelected = time;
    },
    changeAccountSelected(state, account) {
      if (account && state.accountSelected !== account) state.accountSelected = account;
    },
    changeTimeUpdateTerminal(state, timeUpdate) {
      if (state.timeUpdateTerminal !== timeUpdate) state.timeUpdateTerminal = timeUpdate;
    },
    changeIntervalNumber(state, intervalNumber) {
      if (state.intervalNumber !== intervalNumber) state.intervalNumber = intervalNumber;
    },
    changeChartMainIndicators(state, main) {
      if (state.chartMainIndicators !== main) state.chartMainIndicators = main;
    },
    changeIndicatorSettings(state, data) {
      state.indicatorSettings = data;
    },
    changeChartSubIndicator(state, indicator) {
      if (state.chartSubIndicator !== indicator) state.chartSubIndicator = indicator;
    },
    changeUpdateWatchList(state, flag) {
      state.updateWatchList = flag;
    },
  },
  actions: {
    restartMarketData() {
      return window.api.marketData.restartWS();
    },
    updateWS() {
      return window.api.ptfin.updateWS();
    },
    async login({ commit, getters }, { login, password, code }) {
      let result = await window.api.auth.signin({ login, password });
      if (result.status === 'external') {
        let external = {};
        if (code === '') {
          external = await utils.externalAuth({ login, password });
          if (external.type === 'totpRequired') {
            commit('changeTOTP', external);
            return { status: 'totp', text: 'Input code' };
          }
        } else {
          external = await utils.externalTOTP({ cognito: getters.getTOTP, code });
        }

        if (external.type !== 'onSuccess') {
          result = { status: 'unlogged', text: 'Incorrect login or password', token: null };
        } else {
          if (result.refreshToken) {
            const revoke = await utils.revokeToken({ token: result.refreshToken });
            console.log('revokeToken: ', revoke);
            if (this.status === 200) window.api.auth.deleteSession({ token: result.refreshToken });
          }
          result = await window.api.auth.session({ login, password, session: external.session });
        }
      }
      if (result.status === 'logged') commit('changeToken', result.token);
      return { status: result.status, text: result.text };
    },
    async resetPassword({ commit }, formData) {
      return window.api.auth.resetPassword(formData);
    },
    // async logout({ dispatch, rootState }) {
    // dispatch('autoUpdate', null);
    // rootState.router.push({ name: 'login' });
    // },
    resetState({ state, dispatch }) {
      // dispatch('autoUpdate', null);
      clearTimeout(state.intervalNumber);
      localStorage.removeItem('session.token');
      Object.assign(state, storeDefault.initialState);
    },
    async pullUser({ commit }) {
      commit('changeUser', await window.api.auth.userData());
    },
    async pullPersonalData({ getters, commit }) {
      const response = await window.api.user.getData({ id: getters.getUser.id });
      if (response.error) console.error('pullPersonalData Error', response.text, response.data);
      commit('changePersonalData', boarding.makePersonalData(response.data));
    },
    async pullProfile({ getters, commit }) {
      const response = await window.api.user.getProfile({ id: getters.getUser.id });
      if (response.error) console.error('pullProfile Error', response.text, response.data);
      commit('changeProfile', boarding.makeProfile(response.data));
    },
    async updateAutoApproval({ commit }, { type, data }) {
      await window.api.instruction.approval({ type, data });
      commit('changeAutoApproval', { type, data });
    },
    // СКОРОЕЕ ВСЕГО НАДО ПЕРЕИМЕНОВАТЬ
    async updateWithdrawal({ commit }, { type, data }) {
      await window.api.instruction.withdrawal({ type, data });
      commit('changeWithdrawal', { type, data });
    },
    async pullDocuments({ getters, commit }) {
      const customer = getters.getAccounts[getters.getAccountSelected];
      if (customer !== undefined) {
        commit('changeDocuments', await window.api.user.documents({ id: customer.contact_id }));
      }
    },
    async pullAccounts({ commit }) {
      const data = await window.api.ptfin.accounts();
      const accounts = {};
      // if (Object.keys(data).length === 0) accounts[null] = storeDefault.initialAccount();
      // else
      for (const key in data) {
        accounts[key] = storeDefault.initialAccount(data[key]);
      }
      commit('changeAccounts', accounts);
    },
    async updateAccountSelected({ dispatch }, account) {
      await dispatch('updateSettings', { setting: { main: { account } }, update: true });
    },
    async updateSelectedSymbol({ getters, dispatch, commit }, symbol, force = false) {
      if (((symbol && symbol !== getters.selectedSymbol) || force) && !['USD', 'EUR'].includes(symbol)) {
        commit('changeSelectedSymbol', symbol);
        commit('changeOrder', { symbol });
        dispatch('pullQuoteSymbol', symbol);
        dispatch('updateSettings', { setting: { main: { symbol } } });
      }
    },
    async updateSettings({ dispatch }, { setting, update = false }) {
      await window.api.settings.update({ updates: setting });
      if (update) dispatch('pullSettings');
    },
    async pullSettings({ getters, commit, dispatch }) {
      const user = getters.getUser;
      const main = await window.api.settings.get({ type: 'main' });
      const chart = await window.api.settings.get({ type: 'chart' });
      if (main.orderType === undefined) main.orderType = 'item';
      if (main.orderValue === undefined) main.orderValue = 100;
      if (main.wls === undefined) {
        const watchList = await window.api.watchList.get();
        let wls = {};
        for (const name of watchList.map((each) => each.name)) wls[name] = name;
        dispatch('updateSettings', { setting: { main: { wls: wls } } });
        main.wls = wls;
      }

      user.wls = main.wls;
      commit('changeUser', user);

      commit('changeOrderSetting', { type: main.orderType, value: main.orderValue });

      commit('changeTimeUpdateTerminal', main.refresh);

      if (main.confirmation === undefined) {
        main.confirmation = true;
        dispatch('updateSettings', { setting: { main: { confirmation: main.confirmation } } });
      }
      commit('changeConfirmation', main.confirmation);

      let account = null;
      if (main.account !== 'empty') {
        account = main.account || Object.keys(getters.getAccounts)[0];
        commit('changeAccountSelected', account);
        commit('changeOrder', { accounts: [storeDefault.initialAccount(getters.getAccounts[account])] });
      }

      // const account = main.account || Object.keys(getters.getAccounts)[0] || null;
      // commit('changeAccountSelected', account);
      // commit('changeOrder', { account: storeDefault.initialAccount(getters.getAccounts[account]) });

      if (main.symbol) {
        dispatch('updateSelectedSymbol', main.symbol);
        dispatch('pullDataSymbols');
      }

      commit('changeTimeFrameSelected', parseInt(chart.timeframe));
      commit('changeChartType', chart.type);
      if (!Array.isArray(chart.main)) chart.main = chart.main === '' ? [] : [{ name: chart.main, value: chart.main }];
      commit('changeChartMainIndicators', chart.main);
      commit('changeChartSubIndicator', chart.sub);

      if (account) {
        await dispatch('pullPortfolio');
        dispatch('pullOrders', {});
        commit('updatePortfolio');
      }
    },
    async pullWatchList({ getters, commit, dispatch }) {
      // const watchList = await window.api.watchList.get({ name: 'main' });
      const user = getters.getUser;
      const watchList = await window.api.watchList.get();
      const instruments = [];
      for (const each of watchList) {
        each.price = 0.0;
        each.change = 0.0;
        each.changeP = 0.0;
        if (each.instrument === null || Object.keys(each.instrument).length === 0) {
          each.instrument = { symbol: each.symbol, asset_category: null, listing_exchange: each.source };
        }
        instruments.push(each.instrument);
        if (user.wls[each.name] === undefined) user.wl[each.name] = each.name;
      }
      const validNames = new Set(watchList.map((item) => item.name));
      for (const key in user.wls) {
        if (!validNames.has(key)) {
          delete user.wls[key];
        }
      }
      dispatch('updateSettings', { setting: { main: { wls: user.wls } } });
      commit('changeUser', user);
      commit('changeUpdateWatchList', true);
      commit('changeWatchList', watchList);
      dispatch('addSymbols', { instruments });

      if (!getters.getSelectedSymbol) {
        dispatch('updateSelectedSymbol', instruments[0].symbol);
        dispatch('pullDataSymbols');
      }
    },
    async addInstrumentWL({ getters, dispatch }, { name, instrument }) {
      if (getters.getWatchList.findIndex((each) => each.name === name && each.symbol === instrument.symbol) !== -1) {
        return void console.log('Error: ', 'symbol with this name already exists');
      }
      // console.log('innstrument >:', name, instrument);
      if (instrument.exchange === undefined) instrument.exchange = instrument.listing_exchange;
      const res = await window.api.watchList.add({ name, instrument });
      if (res) return dispatch('pullWatchList');

      // console.log('store res :', res, name, instrument);
      return void console.log('Error addInstrumentWL: ', res);
    },
    async deleteInstrumentWL({ getters, commit, dispatch }, { name, instrument }) {
      if (instrument.source === undefined) instrument.source = instrument.listing_exchange;
      const res = await window.api.watchList.delete({ name, instrument });
      if (res) {
        commit('deleteSymbol', instrument.symbol);
        // window.api.marketData.deleteQuoteSymbol({ instrument.symbol });
        return dispatch('pullWatchList');
      }
      return void console.log('Error deleteInstrumentWL: ', res);
    },
    autoUpdate({ commit, getters, dispatch }, newTimeUpdateTerminal = 5) {
      clearTimeout(getters.getIntervalNumber);
      // if (intervalNumber === null && getters.isLoggedIn) setTimeout(() => dispatch('pullDataSymbols'), 1000);
      // clearTimeout(intervalNumber);
      // let intervalId = null;
      // if (newTimeUpdateTerminal !== null)
      commit(
        'changeIntervalNumber',
        setInterval(
          () => {
            dispatch('pullOrders', {});
            dispatch('pullDataSymbols');
          },
          parseInt(newTimeUpdateTerminal) * 1000,
        ),
      );
    },
    async initialData({ getters, dispatch }, { end }) {
      if (end === undefined) end = new Date(new Date().getTime() + 5000);
      const portfolio = getters.getPortfolio;
      await utils.pause(300);
      dispatch('pullDataSymbols');
      if (new Date() < end && portfolio.items.some((item) => item.price === 0.0)) dispatch('initialData', { end });
    },
    async pullDataSymbols({ commit, getters, dispatch }) {
      const symbols = getters.getSymbols;
      const data = await window.api.marketData.getDataSymbols({ symbols: Object.keys(symbols) }).catch((error) => {
        if (error.code === 403) {
          console.log('getDataSymbols:', error);
          commit('changeToken', '');
        } else {
          throw error;
        }
      });
      for (const symbol in data) {
        for (const key in data[symbol]) {
          symbols[symbol][key] = data[symbol][key];
        }
      }
      commit('changeSymbols', symbols);

      dispatch('updateWatchList');
      commit('updatePortfolio');
    },
    async pullQuoteSymbol({ getters, commit }) {
      const quotes = await window.api.marketData.getQuoteSymbol({ symbol: getters.getSelectedSymbol });
      commit('changeLevelI', quotes);
    },
    async pullChartData({ commit }, { instrument, period, limit = 1000 }) {
      return window.api.marketData.addChartSymbols({ instruments: [instrument], period, limit, wait: 2000 });
    },
    async pullPortfolio({ commit, getters, dispatch }) {
      const portfolio = storeDefault.initialPortfolio();
      commit('changePortfolio', portfolio);
      const data = await window.api.ptfin.portfolio({ accounts: [getters.getAccountSelected] });
      calc.processingData(data.rows);
      if (data.rows !== undefined) {
        for (const row of data.rows) {
          if (portfolio.date_time === null || portfolio.date_time > row.date_time) portfolio.date_time = row.date_time;
          if (row.transactions === undefined) row.transactions = row.purchase_details.transactions;
          const position = calc.makePortfolioByRow(row);
          calc.positionChange(position);
          portfolio.items.push(position);
          dispatch('addSymbols', { instruments: [{ ...row.instrument }] });
        }
      }
      commit('changePortfolio', portfolio);
      dispatch('initialData', {});
    },
    async pullOrders({ commit, getters, dispatch }, { days = 30, force = false }) {
      const portfolio = getters.getPortfolio;
      const orders = await window.api.ptfin.orders({ accounts: [getters.getAccountSelected], days, force });
      calc.processingData(orders);
      // orders.sort((a, b) => (a.status_time > b.status_time ? 1 : a.status_time < b.status_time ? -1 : 0));
      let updateTotal = false;
      for (const order of orders) {
        let updateOrder = false;
        let assetId = null;
        const maxDateTrdes = order.trades.reduce((max, a) => (a.date_time > max ? a.date_time : max), portfolio.date_time);
        if (
          (order.trades.length === 0 && order.status_time > portfolio.date_time && Math.abs(order.filled) > 0.0) ||
          maxDateTrdes > portfolio.date_time
        ) {
          assetId = portfolio.items.findIndex((item) => item.instrument.symbol === order.instrument.symbol);
          if (assetId === -1) {
            portfolio.items.push(
              calc.makePortfolioByRow({
                account: order.account,
                date_time: portfolio.date_time,
                instrument: order.instrument,
              }),
            );
            assetId = portfolio.items.findIndex((item) => item.instrument.symbol === order.instrument.symbol);
          }

          if (order.trades.length === 0) {
            order.trades = calc.makeTradesByOrder(order, calc.getPrice(getters.getSymbols[order.instrument.symbol]));
          }
          for (const trade of order.trades) {
            if (!(trade.transaction_id in portfolio.items[assetId].transactions)) {
              updateTotal = updateOrder = true;
              // console.log(new Date().getTime(), JSON.stringify(trade));
              if (trade.list === undefined) trade.list = 'trades';
              // Если до этого создалась транзакция из order, ее надо удалить
              // portfolio.items[assetId].transactions.splice(order.transaction_id, 1);
              delete portfolio.items[assetId]?.transactions?.[order.transaction_id];
              portfolio.items[assetId].transactions[trade.transaction_id] = calc.makeTransaction(trade);
            }
          }
        }
        dispatch('addSymbols', { instruments: [{ ...order.instrument }] });
        if (updateOrder) calc.positionChange(portfolio.items[assetId]);
      }
      if (updateTotal) calc.updateCash(portfolio.items);

      commit('changePortfolio', portfolio);
      commit('changeListOrders', []);
      commit('changeListOrders', orders);
    },
    addSymbols({ commit, getters }, { instruments }) {
      const symbols = getters.getSymbols;
      const levelI = getters.getLevelI;
      let update = false;
      for (const each of instruments) {
        if (each.symbol !== 'USD' && each.listing_exchange !== 'Empty') {
          if (!(each.symbol in symbols)) {
            update = true;
            symbols[each.symbol] = storeDefault.initialSymbolData(each);
            commit('changeSymbols', symbols);
          }
          if (!(each.symbol in levelI)) {
            update = true;
            commit('changeLevelI', storeDefault.initialLevelI(each.symbol));
          }
        }
      }
      if (update) {
        window.api.marketData.addQuoteSymbols({ instruments: symbols });
      }
    },
    updateWatchList({ commit, getters }) {
      const symbols = getters.getSymbols;
      const watchList = getters.getWatchList;
      for (const each of watchList) {
        each.price = symbols[each.symbol] ? symbols[each.symbol].rtc || symbols[each.symbol].lp : 0.0;
        each.change = symbols[each.symbol] ? symbols[each.symbol].ch : 0.0;
        each.changeP = symbols[each.symbol] ? symbols[each.symbol].chp / 100 : 0.0;
      }
      commit('changeWatchList', watchList);
    },
    async fetchInstrument({ commit }, needle) {
      const list = await window.api.marketData.symbolSearch(needle);
      commit('changeSearchList', list);
    },
    async pullClientsListNames() {
      this.commit('changeClientsListNames', [
        { id: 'all', name: 'Все клиенты' },
        { id: 'all1', name: 'Все клиенты2' },
      ]);
    },
    async pullClientList({ getters }, { name }) {
      const list = [];
      if (name === 'all') {
        for (const account of Object.values(getters.getAccounts)) {
          list.push({ id: account.contract_id, fio: account.full_name, account: account.account });
        }
      }
      return list;
    },
    async sendOrder({ getters, commit, dispatch }) {
      const instruction = getters.getOrder;
      if (instruction.list.length === 0) return void console.log('not client');

      const symbol = getters.getSymbols[instruction.deal.general.symbol];
      let instrument = { api: 'tv' };
      for (const each of [
        'symbol',
        'asset_category',
        'source',
        'type',
        'description',
        'currency_id',
        'country_code',
        'listed_exchange',
        'isin',
        'lp',
      ]) {
        instrument[each] = symbol[each];
      }
      // todo логика проверок
      if (!getters.getModals.orderForm.visible) {
        if (!instruction.confirmation.order && getters.getConfirmation) {
          return void commit('changeModals', {
            orderConfirm: {
              visible: true,
              data: {
                action: instruction.deal.orders[1].action,
                type: instruction.deal.orders[1].type,
                instrument: { symbol: instruction.deal.general.symbol },
                quantity: instruction.deal.general.quantity,
                status: instruction.deal.general.status,
                limitPrice: instruction.deal.orders[1].limitPrice,
                triggerPrice: instruction.deal.orders[1].triggerPrice,
              },
            },
          });
        }

        if (!instruction.confirmation.short && calc.isShort(instruction, getters.getPortfolio, getters.getOrders)) {
          return void commit('changeModals', { shortConfirm: { visible: true, data: {} } });
        }
      }

      instruction.deal.general.date_time = new Date().valueOf();
      // console.log('Оьправлено на серв instruction :', instruction);
      window.api.ptfin.sendOrder({ data: instruction, instrument }).then((data) => {
        dispatch('pullOrders', {});
        if (data.success.length > 0) {
          for (const each of data.success) {
            commit('changeNotifications', {
              text: calc.textSendOrder(each),
              style: 'red',
              type: 'success',
              duration: 2.5,
            });
          }
          console.log('sendOrder: success');
        }
      });
      // commit('changeModals', { orderForm: { visible: false, data: {} } });
      commit('changeNotifications', { text: calc.orderSortText(instruction), type: 'warning', duration: 10 });
      // commit('setDefaultOrder', { type: instruction.deal.orders[1].type, tif: instruction.deal.orders[1].tif });
    },
    async cancelOrder({ dispatch, commit }, order) {
      const res = await window.api.ptfin.cancelOrder({ order });
      if (res) return dispatch('pullOrders', {});
      return void console.log('Error deleteOrder: ', res);
    },
    async forgotPassword({ commit }, data) {
      // console.log('store :', data);
      await window.api.ptfin.resetPassword(data);
    },
    async terminalUser({ commit }, data) {
      return window.api.auth.register(data);
    },
    newKey({ commit }, data) {
      window.api.user.newKey(data);
    },
    inviteTelegram({ getters }) {
      window.api.user.invite({ type: 'telegram', user: getters.getAccounts[getters.getAccountSelected].contact_id });
    },
    async sendPhone({ commit, getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.type = 'phone';
      options.action = 'create';
      const response = await window.api.registration.enrichmen({ form, options });
      commit('changeUser', {
        id: response.data.contact.id,
        login: response.data.contact.phone_mobile,
        type: 'registration',
      });
      return response;
    },
    sendTaxNumber({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.type = 'taxNumber';
      options.action = 'create';
      return window.api.registration.enrichmen({ form, options });
    },
    async sendEnrichment({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      if (form.files !== undefined) {
        for (const file of form.files) {
          file.fileName = file.name;
          file.base64 = await utils.readFileBase64(file);
        }
      }
      options.action = 'enrichment';
      return window.api.instruction.sendForm({ form, options });
    },
    async deleteDocuments({ getters }, file) {
      file.id = getters.getUser.id;
      return window.api.instruction.deleteDocument(file);
    },
    async createInstruction({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.action = 'create';
      return window.api.instruction.sendForm({ form, options });
    },
    async signingInstruction({ getters }, { form, options = {} }) {
      form.id = getters.getUser.id;
      options.action = 'sign';
      return window.api.instruction.sendForm({ form, options });
    },

    async sendStrimDocs({ getters }, data) {
      const file = data.files[0];
      const uploader = window.application.metacom.createBlobUploader(file);
      await window.api.files.upload({
        streamId: uploader.id,
        name: file.name,
      });
      await uploader.upload();
      return { uploadedFile: file };
    },
    // async sendDocs({ getters }, data) {
    // const file = data.files[0];
    // const base64String = await readFileBase64(file);
    // const buffer = await readFileBuffer(file);
    // const responce = await window.api.user.sendDocs({ name: file.name, file: base64String });
    // console.log(responce);
    // },
    async pullExpiration({ commit }, data) {
      const expirations = await window.api.ptfin.expiration(data);
      expirations.sort((a, b) =>
        new Date(a.expiration) > new Date(b.expiration) ? 1 : new Date(a.expiration) < new Date(b.expiration) ? -1 : 0,
      );
      commit('changeExperations', expirations);
    },
    async pullOptions({ commit }, data) {
      const options = await window.api.ptfin.options(data);
      options.sort((a, b) => {
        if (a.symbol > b.symbol) return 1;
        else if (a.symbol < b.symbol) return -1;
        else {
          if (new Date(a.expiration) > new Date(b.experation)) return 1;
          else if (new Date(a.expiration) < new Date(b.experation)) return -1;
          else {
            if (a.strike > b.strike) return -1;
            else if (a.strike < b.strike) return 1;
            else {
              if (a.type > b.type) return 1;
              else if (a.type < b.type) return -1;
              else return 0;
            }
          }
        }
      });
      commit('changeOptions', options);
    },
    async updateOptions({ dispatch }, symbol) {
      const response = await window.api.ptfin.updateOptions({ symbol });
      console.log(response);
      dispatch('pullExpiration', { symbol });
      return 'ok';
    },
  },
  modules: {},
});
